import * as React from "react";
import { graphql, PageProps } from "gatsby";
import styled from "styled-components";

import { colors, fontSizes, MOBILE_BREAKPOINT } from "@util/constants";
import { Container, P } from "@util/standard";
import { BlocksContent } from "@global";
import { Query } from "@graphql-types";
import SEO from "@shared/seo";
import Layout from "@shared/layout";

const Wrapper = styled(Container)`
  width: 100%;
  background-color: ${colors.white};
  justify-content: center;
  flex-direction: column;
  text-align: left;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    padding-bottom: 0;
  }
`;

const TextContainer = styled(Container)`
  justify-content: center;
  width: 70%;
  margin: 200px auto 100px auto;
  flex-direction: column;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: 100px auto;
  }
`;
const BlocksCotentContainer = styled(Container)`
  p {
    width: 78%;
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    p {
      width: 95%;
    }
  }
`;
const Title = styled(P)`
  font-size: ${fontSizes.h2.default}px;
  font-weight: bold;
  margin-bottom: 10px;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: ${fontSizes.h2.mobile}px;
  }
`;

interface Props extends PageProps {
  data: Query;
  pageContext: { iban: string; slug: string };
}

const ShippingPolicyPage = ({ data }: Props) => {
  const sanityShippingPolicyPage = data.allSanityShippingPolicyPage.nodes[0];

  if (!sanityShippingPolicyPage ) return null;


  const { shippingContent, title } = sanityShippingPolicyPage;

  return (
    <Layout>
      <Wrapper>
        <SEO seoData={sanityShippingPolicyPage.seo} />
        <TextContainer>
          <Title>{title}</Title>
          <BlocksCotentContainer>
            <BlocksContent blocks={shippingContent?._rawBlocks} />
          </BlocksCotentContainer>
        </TextContainer>
      </Wrapper>
    </Layout>
  );
};

export default ShippingPolicyPage;

export const query = graphql`
  query shippingPolicyQuery($iban: String) {
    allSanityShippingPolicyPage(filter: { region: { iban: { eq: $iban } } }) {
      nodes {
        ...sanityShippingPolicyPage
      }
    }
  }
`;
